<template>
	<div class="body">
		<div class="sponsor">
			<div class="header_body">
				<img class="red_s" src="../../../assets/image/img/logo_red.png">
				<div class="flex-direction-column title_body">
					<div class="title_login">
						中门协教练员管理系统
					</div>
					<div class="title_login2">
						Welcome to the Coach Management System of China Gateball Association
					</div>
				</div>
			</div>
			<div class="body_login">
				<div class="login_body" v-show="loginState">
					<div class="tabs_body">
						<div class="tabs_item" v-for="(item,index) in tabsList" :key="index"
								 @click="chooseIndex=index">
							<span class="unchoose" :class="chooseIndex==index ? 'choose' :''">{{ item }}</span>
							<div class="line" v-if="chooseIndex==index"></div>
							<div class="line2" v-else></div>
						</div>
						<!--					单位登录-->
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.name}">
						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/iphone.png" class="icon" v-if="!isFocusFrom.name">
							<img src="../../../assets/image/iconLogin/iphone_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('name')"
											@blur="handleBlur('name')"
											v-model="loginForm.name"
											placeholder="请输入手机号或用户名"></el-input>
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.passWord}">

						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/passWord.png" class="icon" v-if="!isFocusFrom.passWord">
							<img src="../../../assets/image/iconLogin/passWord_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('passWord')"
											@blur="handleBlur('passWord')"
											v-model="loginForm.passWord"
											placeholder="请输入密码"></el-input>
					</div>
					<div class="flex">
						<div class="input_width input_width2" :class="{'focus-border': isFocusFrom.code}">
							<div class="icon_body">
								<img src="../../../assets/image/iconLogin/code.png" class="icon" v-if="!isFocusFrom.code">
								<img src="../../../assets/image/iconLogin/code_c.png" class="icon" v-else>
								<div class="line_shu"></div>
							</div>
							<el-input @focus="handleFocus('code')"
												@blur="handleBlur('code')"
												v-model="loginForm.code"
												placeholder="请输入验证码">
							</el-input>
						</div>
						<div class="code" @click="refreshCode">
							<s-identify :identify-code="identifyCode"></s-identify>
						</div>
					</div>
					<span class="register_body" v-if="chooseIndex==0">
						还没账号？<span @click="register(1)" class="register">立即注册</span>
					</span>
					<span class="register_body" v-else></span>
					<div class="btn_body" @click="loginBtn">
						查询
					</div>
				</div>
				<div class="login_body" v-show="!loginState">
					<div class="tabs_body">
						<div class="tabs_item ">
							<span class="choose">个人注册</span>
							<div class="line"></div>
						</div>
						<!--					单位登录-->
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.user}">
						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/user.png" class="icon" v-if="!isFocusFrom.user">
							<img src="../../../assets/image/iconLogin/user_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('user')"
											@blur="handleBlur('user')"
											v-model="loginForm.user"
											placeholder="请输入真实姓名"></el-input>
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.idcard}">

						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/idcard.png" class="icon" v-if="!isFocusFrom.idcard">
							<img src="../../../assets/image/iconLogin/idcard_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('idcard')"
											@blur="handleBlur('idcard')"
											v-model="loginForm.idcard"
											placeholder="请输入身份证号"></el-input>
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.iphone}">

						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/iphone.png" class="icon" v-if="!isFocusFrom.iphone">
							<img src="../../../assets/image/iconLogin/iphone_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('iphone')"
											@blur="handleBlur('iphone')"
											v-model="loginForm.iphone"
											placeholder="请输入密码"></el-input>
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.passWord}">
						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/passWord.png" class="icon" v-if="!isFocusFrom.passWord">
							<img src="../../../assets/image/iconLogin/passWord_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('passWord')"
											@blur="handleBlur('passWord')"
											v-model="loginForm.passWord"
											placeholder="请输入密码"></el-input>
					</div>
					<div class="input_width" :class="{'focus-border': isFocusFrom.passWordCopy}">

						<div class="icon_body">
							<img src="../../../assets/image/iconLogin/passWord.png" class="icon" v-if="!isFocusFrom.passWordCopy">
							<img src="../../../assets/image/iconLogin/passWord_c.png" class="icon" v-else>
							<div class="line_shu"></div>
						</div>
						<el-input @focus="handleFocus('passWordCopy')"
											@blur="handleBlur('passWordCopy')"
											v-model="loginForm.passWordCopy"
											placeholder="请再次输入密码"></el-input>
					</div>
					<span class="register_body">
						已有账号，<span @click="register(2)" class="register">立即登录</span>
					</span>
					<div class="btn_body" @click="registerBtn">
						注册
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import SIdentify from '../../../components/SIdentify/SIdentify.vue'
export default {
	components: {
		SIdentify,
	},
	name: "achievementLogin",
	data() {
		return {
			loginState: true,
			tabsList: ["个人登录", "单位登录"],
			chooseIndex: 0,
			loginForm: {
				name: "",
				passWord: "",
				code: "",
				iphone: "",
				idcard: "",
				user: "",
				passWordCopy: ""
			},
			timer: null,
			isFocusFrom: {
				name: false,
				code: false,
				passWord: false,
				iphone: false,
				idcard: false,
				user: false,
				passWordCopy: false,
			},
			identifyCode: "",// 验证码
			identifyCodes: '1234567890',//验证码随机范围
		}
	},
	mounted() {
		this.refreshCode()
	},
	methods: {
		registerBtn() {

		},
		register(index) {
			console.log(this.loginState)
			this.loginState = !this.loginState
		},
		loginBtn() {
			if (this.identifyCode != this.isFocusFrom.code) {
				this.$message.error('验证码错误')
				this.refreshCode()
				return
			}
		},
		// 验证码点击刷新方法
		refreshCode() {
			this.identifyCode = ''
			// 4位验证码可以根据自己需要来定义验证码位数
			this.makeCode(this.identifyCodes, 4)
		},
		// 随机数
		randomNum(min, max) {
			max = max + 1
			return Math.floor(Math.random() * (max - min) + min)
		},
		// 随机生成验证码字符串
		makeCode(data, len) {
			for (let i = 0; i < len; i++) {
				this.identifyCode += data[this.randomNum(0, data.length - 1)]
			}
		},
		handleFocus(name) {
			this.isFocusFrom[name] = true
		},
		handleBlur(name) {
			this.isFocusFrom[name] = false
		},
	}
}
</script>

<style scoped>

.body {
	background-image: url('../../../assets/image/img/bj.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.sponsor {
//width: 1360px; display: flex;
	justify-content: space-between;
	padding: 0 10%;
	margin: 0 auto;
	height: 100vh;
}

.header_body {
	padding: 42px 0px;
}

.red_s {
	width: 300px;
	height: 76.471px;
}

.title_body {
	margin-top: 200px;

	.title_login {
		font-weight: 800;
		font-size: 56px;
		color: #212F82;
		line-height: 59px;
		letter-spacing: 4px;
	}

	.title_login2 {
		width: 580px;
		font-weight: 400;
		font-size: 22px;
		color: #212F82;
		line-height: 40px;
		margin-top: 18px;
	}
}

.body_login {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 14vh;


}

.login_body {
	padding: 40px 34px 34px;
//margin-right: 200px; background: #FFFFFF;
	margin-top: 44px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tabs_body {
	display: flex;
	cursor: pointer;
	margin-bottom: 20px;
}

.tabs_item {
	width: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease; /* 添加平滑过渡效果 */
}

.unchoose {
	font-size: 18px;
	color: #818181;
	height: 24px;
	display: flex;
	align-items: flex-end;
}

.line {
	width: 36px;
	height: 4px;
	background: #212F82;
	margin: 15px 0;
	transition: all 0.5s ease;
}

.line2 {
	width: 36px;
	height: 4px;
	margin: 15px 0;
	transition: all 0.1s ease;
}

.choose {
	font-weight: bold;
	font-size: 24px;
	color: #212F82;
	text-align: center;
	transition: all 0.3s ease; /* 添加平滑过渡效果 */
}

.count_line {
	border-left: 1px solid #666666;
	padding-left: 30px;
}

.code {
	margin-bottom: 18px;
	border: 1px solid #BEBEBE;
	margin-left: 10px;
	border-radius: 3px;
	padding: 3px;
}

.input_width {
	margin-bottom: 18px;
	width: 324px;
	height: 45px;
	border: 1px solid #BEBEBE;
	padding-left: 50px;
	border-radius: 5px;
	position: relative;
	display: flex;
	align-items: center;

	::v-deep input::-webkit-input-placeholder {
		height: 49px;
		font-size: 16px;
		color: #bbbbbb;
	}

	::v-deep .el-input__inner {
		height: 45px;
		font-size: 16px;
	}
}

.icon_body {
	position: absolute;
	top: 10px;
	left: 13px;
	display: flex;
	align-items: center;
}

.icon {
	width: 24px;
	height: 24px;
}

.line_shu {
	width: 1px;
	height: 14px;
	margin-left: 10px;
	background-color: #BEBEBE;
}

.focus-border {
	border-color: #212F82 !important; /* 你想要的颜色 */
}

.input_width2 {
	width: 200px;
}

/deep/ .el-input__inner {
//background-color: #F9F9F9; border: none;
}

/deep/ .el-input__inner::placeholder {
	color: #6E6E6E;
}

/deep/ .el-input-group__append, .el-input-group__prepend {
	background-color: #F9F9F9;
	border: none;
}

.register_body {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #666666;
	margin-bottom: 36px;
	height: 18px;
	line-height: 18px;
}

.register {
	font-size: 14px;
	cursor: pointer;
	color: #212F82;
}

.btn_body {
	width: 380px;
	height: 50px;
	background: #212F82;
	border-radius: 5px;
	font-weight: 500;
	font-size: 16px;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
}

/deep/ .el-form-item__label {
	font-size: 16px !important;
}

/deep/ .el-select .el-input .el-select__caret {
	color: black;
}

</style>
